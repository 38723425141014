import * as googleMaps from '@googlemaps/js-api-loader';
import { useClickOutside } from '~/hooks/useClickOutside';
import debounce from 'lodash-es/debounce';
import type React from 'react';
import { type ReactElement, useEffect, useRef, useState } from 'react';

const { Loader } = googleMaps;

const loadGoogleMapsApi = (callback: () => void): void => {
  const loader = new Loader({
    apiKey: 'AIzaSyCp0SUPA1bmkrR4AgNqwLwbow9nnuaAv7o',
    id: '__googleMapsForAddressTypeAhead', // for this issue - https://github.com/google-map-react/google-map-react/issues/1016
    version: 'weekly',
    libraries: ['places'],
  });

  void loader.importLibrary('places').then(async () => {
    callback?.();
  });
};

let AutocompleteService: any = null;

export interface Address {
  address1: string;
  address2?: string;
  city: string;
  state: string;
  zip: string;
  lat?: string | number;
  lng?: string | number;
}

interface Props {
  address?: Address | string;
  onSelect?: (address: Address, autoSelect: boolean) => void;
  showZip?: boolean;
  showAddressLabel?: boolean;
  showAddress2?: boolean;
  showAddress2Label?: boolean;
  showInstructions?: boolean;
  onInstructionsChange?: (instructions: string) => void;
  required?: boolean;
  errorState?: string;
  className?: string;
  placeholder?: string;
}

export default function AddressTypeahead({
  onSelect,
  showZip,
  showAddressLabel,
  showAddress2,
  showAddress2Label,
  showInstructions,
  address,
  onInstructionsChange,
  required,
  errorState,
}: Props): ReactElement {
  const [value, setValue] = useState<string>('');
  const [predictions, setPredictions] = useState<google.maps.places.AutocompletePrediction[]>([]);

  // Address breakdown
  // Address 2 is not included as that is custom input
  const [address1, setAddress1] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [state, setState] = useState<string>('');
  const [zip, setZip] = useState<string>('');
  const [lat, setLat] = useState<number | string>('');
  const [lng, setLng] = useState<number | string>('');

  const inputRef = useRef<HTMLInputElement | null>(null);
  const typeaheadRef = useRef<HTMLDivElement | null>(null);

  const getComponent = (address: google.maps.GeocoderResult, key: string): string | null =>
    address.address_components.find((component: any) => component.types.includes(key))?.short_name || null;

  // If the user clicks outside the typeahead, clear the predictions
  useClickOutside(typeaheadRef, () => {
    clearPredictions();
  });

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Tab') {
      clearPredictions();
    }

    if (e.key === 'Enter') {
      e.preventDefault();
      if (predictions.length > 0) {
        onSelectPrediction(predictions[0]);
      }
    }
  };

  const onKeyUp = (event: any): void => {
    switch (event.keyCode) {
      // case 13: // Enter
      case 27: // Escape
        clearPredictions();
        break;
      default:
        getPredictions(value);
    }
  };

  const onSelectPrediction = (prediction: any, autoSelect: boolean = false): void => {
    const { google } = window as any;

    inputRef?.current?.blur();
    clearPredictions();

    // Google formats the address with the country and not the zip, so we need to get the zip from the address_components after geocoding
    const displayValue: string = prediction.description.replace(', USA', '');

    // Geocode the address
    if (google) {
      const Geocoder: google.maps.Geocoder = new google.maps.Geocoder();
      void Geocoder.geocode(
        { placeId: prediction.place_id },
        (results: google.maps.GeocoderResult[] | null, status: google.maps.GeocoderStatus) => {
          if (status === google.maps.GeocoderStatus.OK) {
            const address = results?.[0];

            if (address) {
              setLat(address.geometry.location.lat());
              setLng(address.geometry.location.lng());

              const zipCode = getComponent(address, 'postal_code') || '';

              // TODO: Sometimes the address cuts off the street number?
              const address1 = address.formatted_address.split(',')[0];
              const zip = getComponent(address, 'postal_code') || '';
              const city =
                getComponent(address, 'locality') ||
                getComponent(address, 'sublocality') ||
                getComponent(address, 'neighborhood') ||
                '';
              const state = getComponent(address, 'administrative_area_level_1') || '';

              setAddress1(address1);
              setZip(zip);
              // City is a little more complicated because it can be in different components
              setCity(city);
              setState(state);

              // Set the displayed input to show the full address including Zip Code (if it isn't already included)
              setValue(displayValue + (/^\d+$/.test(displayValue.slice(-5)) ? '' : ` ${zipCode}`));

              // Fire an event for the parent component to handle
              onSelect?.(
                {
                  address1,
                  address2: value,
                  city,
                  state,
                  lat: address.geometry.location.lat(),
                  lng: address.geometry.location.lng(),
                  zip,
                },
                (autoSelect = false),
              );
            }
          }
        },
      );
    }
  };

  const clearPredictions = (): void => {
    setPredictions([]);
  };

  const getPredictions = debounce(
    (val: string, autoSelect: boolean = false) => {
      const { google } = window as any;
      if (google) {
        if (!AutocompleteService) {
          AutocompleteService = new google.maps.places.AutocompleteService({});
        }

        if (val?.trim()) {
          AutocompleteService.getPlacePredictions(
            {
              input: val,
              types: ['address'],
            },
            (
              predictions: google.maps.places.AutocompletePrediction[],
              status: google.maps.places.PlacesServiceStatus,
            ) => {
              if (status !== google.maps.places.PlacesServiceStatus.OK) {
                return;
              }
              if (autoSelect && predictions.length > 0) {
                onSelectPrediction(predictions[0], autoSelect);
              } else {
                setPredictions(predictions);
              }
            },
          );
        } else {
          clearPredictions();
        }
      }
    },
    300,
    { leading: true },
  );

  // Prefill the address if it's passed in
  useEffect(() => {
    // TODO: Hack to get the map to re-render; fix
    const existingScript = document.getElementById('googleMaps');
    if (existingScript) {
      document.body.removeChild(existingScript);
    }

    loadGoogleMapsApi(() => {
      if (address) {
        if (typeof address === 'string') {
          getPredictions(address);
          return;
        }

        // Check if the address is already formatted
        if (address.address1 && address.city && address.state) {
          const addressPrefilled = `${address?.address1} ${address?.city} ${address?.state}`;
          getPredictions(addressPrefilled, true);
        }
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="relative">
      <div className="flex flex-col relative" ref={typeaheadRef}>
        <div className={location.pathname.includes('appointment') && !showAddressLabel ? 'flex gap-5' : ''}>
          <div
            className={
              location.pathname.includes('appointment') && !showAddressLabel
                ? 'flex gap-2 w-2/3'
                : 'grid grid-cols-4 gap-4'
            }
          >
            <div className={location.pathname.includes('appointment') && !showAddressLabel ? 'w-2/3' : 'col-span-3'}>
              <input
                ref={inputRef}
                type="text"
                autoComplete="street-address"
                required={required}
                onKeyUp={onKeyUp}
                onKeyDown={onKeyDown}
                onChange={event => {
                  setValue(event.target.value);
                }}
                value={value}
                className={errorState && !value ? 'ml-1 w-full border-red-500' : 'w-full ml-1 mb-1'}
              />
              {showAddressLabel && errorState && !value ? (
                <span className={'ml-1 text-red-500 text-xs'}>{errorState}</span>
              ) : null}
              {showAddressLabel && !errorState ? (
                <span className="ml-1 block text-[11px] font-medium text-slate-900">Home Address</span>
              ) : null}
            </div>
            {showAddress2 && value ? (
              <div className={location.pathname.includes('appointment') && !showAddressLabel ? 'w-1/3' : 'col-span-1'}>
                <input
                  name="address2"
                  defaultValue={address?.address2 ?? ''}
                  type="text"
                  placeholder="Unit/Apartment #"
                  className="w-full ml-1 mb-1"
                />
                {showAddress2Label ? (
                  <span className="mb-1 ml-1 block text-[11px] font-medium text-slate-900">Unit/Apartment #</span>
                ) : null}
              </div>
            ) : null}
          </div>
          <div className="relative w-1/3">
            {showInstructions && value ? (
              <div className="w-full">
                <textarea
                  className="w-full h-20"
                  name="specialInstructions"
                  placeholder="Special instructions (gate code, parking info, attack dog, ppe, etc.)"
                  onChange={e => {
                    onInstructionsChange?.(e.target.value);
                  }}
                ></textarea>
              </div>
            ) : null}
            {showZip && zip ? (
              <div className="width-64 hidden">
                <input type="text" value={zip} readOnly className="w-full text-center" />
              </div>
            ) : null}
          </div>
        </div>
        <input type="hidden" name="address1" value={address1} readOnly></input>
        <input type="hidden" name="city" value={city} readOnly></input>
        <input type="hidden" name="state" value={state} readOnly></input>
        <input type="hidden" name="zip" value={zip} readOnly></input>
        <input type="hidden" name="lat" value={lat} readOnly></input>
        <input type="hidden" name="lng" value={lng} readOnly></input>
      </div>
      {predictions.length > 0 ? (
        <div
          className={`relative z-9999 ${showInstructions ? 'mt-[-2rem]' : ''} w-full rounded-md border border-slate-400 bg-white shadow-lg sm:text-sm max-h-100`}
        >
          {predictions.map((prediction: any) => (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
            <div
              key={prediction.place_id}
              className="cursor-pointer border-b border-slate-200 p-3 hover:bg-slate-50"
              onClick={e => {
                e.stopPropagation();
                onSelectPrediction(prediction);
              }}
            >
              {prediction.description}
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
}
